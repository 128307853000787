@font-face {
  font-family: 'Helvetica Neue';
  src: url('assets/fonts/HelveticaNeue.ttf');
}
@font-face {
  font-family: 'Helvetica Neue';
  font-weight: bold;
  src: url('assets/fonts/HelveticaNeuBold.ttf');
}
@font-face {
  font-family: 'Muli';
  src: url('assets/fonts/Muli.ttf');
}
@font-face {
  font-family: 'Raleway';
  src: url('assets/fonts/Raleway.ttf');
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Helvetica';
  color: white;
  background-color: #ebebeb;
  overflow-x: hidden;
}

a {
  text-decoration: none;
}
